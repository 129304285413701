import React from 'react'
import styles from './Contact.module.css'
import Queryform from '../../common/Queryform'
import FindUsHere from '../../common/FindUsHere'
 
const Contact = () => {
  

  return (
    <div className={styles.connectcontainer}>
      <div className={styles.headtext}>
        <div className={styles.head}>Connect</div>
        <div>We are always excited to discuss your unique requirements that are not limited to our current offerings and wish to hear from you.
        </div>
      </div>
      <div className={styles.queryconatainer}>
        <div className={styles.queryhead}>
          <h4>Still have Queries?</h4>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-6'>
            <div className={styles.form}>
              <Queryform />
            </div>
          </div>
          <div className='col-md-6 '>
            <div className={styles.image}  ></div>
            <div className={styles.imagebelowTxt}><p>Can’t find what you’re looking for? Send us an email.</p>
              <div className='row'>
                <div className='col-md-6'>
                  <p>Commercial Printing</p><p><a href="">commercial.sales@kalajyothi.com</a></p>
                  <p>Magazines and Bookwork</p><p><a href="">magazine.sales@kalajyothi.com</a></p>
                  <p><a href="">bookwork@kalajyothi.com</a></p>
                </div>
                <div className='col-md-6'>
                  <p>Packaging</p><p><a href="">box@kalajyothi.com</a></p>
                  <p>Pharma print production</p><p><a href="">pharma.sales@kalajyothi.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindUsHere/>

    </div>
  )
}

export default Contact
