"use client"
import React, { useEffect, useRef, useState } from 'react';
import styles from './TemplateLayout1.module.css'

const TemplateLayout1 = () => {
  const cardRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [visibleCards, setVisibleCards] = useState([false, false, false, false]);

  const handleScroll = () => {
    cardRefs.forEach((ref, index) => {
      const card = ref.current;
      if (card) {
        const rect = card.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= windowHeight && rect.bottom >= 0) {
          setVisibleCards((prev) => {
            const newVisibleCards = [...prev];
            newVisibleCards[index] = true;
            return newVisibleCards;
          });
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}>
        <img
          src="/images/flightt.jpg"// Replace with your image path
          alt="Side Image"
          className={styles.image}
        />
      </div>

      <div className={styles.contentSection}>
        <div className={styles.headerContent}>
          <h2>Flying Zone</h2>
          <p>Some description text goes here</p>
        </div>
        <div className={styles.cards}>
          {[1, 2, 3, 4].map((card, index) => (
            <div
              ref={cardRefs[index]}
              key={index}
              className={`${styles.card} ${visibleCards[index] ? styles.visible : ''}`}
            >
              <h3>Flight {card}</h3>
              <p>Flying is more than a  which fill a lifetime. </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TemplateLayout1;

