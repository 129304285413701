import React, { useEffect, useRef, useState } from 'react'; 
import styles from './Three.module.css'; // Import the styles

const Three = () => {
    const [activeIndices, setActiveIndices] = useState([0, 0, 0]);
    const carousels = [
        ['images/moon.jpg', 'images/green.jpg', 'images/water.jpg'],
        ['images/bird.jpg', 'images/birdd.jpg', 'images/inter.jpg'],
        ['images/flightt.jpg', 'images/moon.jpg', 'images/bird.jpg'],
    ];

    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [triggerScrollAnimation, setTriggerScrollAnimation] = useState(false);

    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const rect = container.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const visible = rect.top <= windowHeight && rect.bottom >= 0;
            setIsVisible(visible);
            
            if (visible) {
                setTriggerScrollAnimation(true);
                setTimeout(() => setTriggerScrollAnimation(false), 1000);
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndices(prevIndices =>
                prevIndices.map((index, carouselIndex) => (index + 1) % carousels[carouselIndex].length)
            );
        }, 5000); // Change slide every 5 seconds

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        setTimeout(() => {
            setInitialLoad(false);
        }, 1000);

        return () => {
            clearInterval(interval);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div
                ref={containerRef}
                className={`${styles.visibleText} 
                    ${initialLoad ? styles.fadeInUp : ''}  
                    ${isVisible && !initialLoad ? styles.scrollVisible : ''} 
                    ${triggerScrollAnimation ? styles.scrollAnimate : ''}`}
            >
                <p>
                    Amantra is your trusted partner in providing comprehensive solutions across various sectors.
                    We focus on quality and customer satisfaction, successfully managing projects for both
                    government and private clients. Our dedicated team is committed to understanding your unique
                    needs and delivering timely results.
                </p>
            </div>

            {carousels.map((carousel, index) => (
                <div className={styles.carousel} key={index}>
                    <div className={styles.carouselInner}>
                        {carousel.map((image, idx) => (
                            <div
                                className={`${styles.carouselItem} ${activeIndices[index] === idx ? styles.active : ''}`}
                                key={idx}
                            >
                                <img src={image} alt={`Slide ${idx + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Three;
