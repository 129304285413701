import React from 'react'
import Carousel from '../Carousel'
import Card from '../Tile1'
const Home = () => {
    return (
        <div>
            <Carousel />
            <Card />
      </div>

    )
}

export default Home
