import React from 'react';
import styles from './Portfolio.module.css';

const Portfolio = () => {

  return (
    <div className={styles.portfolioContainer}>
      <div className={styles.project1Card}>
        <img src="images/portfolioimg1.jpg" alt="Project1 Image" />
        <h3>Government Projects</h3>
        <ul >
          <li>● Branded 10,000 Health and Wellness Centers with indoor signage, including sandwich
            boards.</li>
          <li>● Enhanced 1,000 Primary Health Centers with comprehensive indoor branding solutions.</li>
          <li>● Executed outdoor branding for 4,000 Y.S.R Village Clinics with front-lit boards.</li>
          <li>● Supplied foam and vinyl boards for a 500,000 weaker section housing project,
            showcasing our ability to manage large-scale governmental initiatives.</li>
        </ul>
      </div>
      <div className={styles.project2Card}>
        <img src="images/portfolioimg2.png" alt="Project2 Image" />
        <h3>Private Clients</h3>
        <ul>
          <li>● Collaborated with numerous private companies across various industries. Our dedication
            to quality and timely delivery, combined with valuable client insights, has established us
            as a trusted partner. We consistently adapt to market trends and focus on improving our
            services</li>
        </ul>
      </div>
    </div>
  );
};

export default Portfolio;