import React from 'react'
import TemplateLayout from '../Tile3/imageandtext'

import Logo from '../Tile4/Logos/Logo'
import TemplateLayout1 from '../Tile5/TempateLayout1'
const Services = () => {
    return (
        <div>
            <TemplateLayout />
            <Logo />
           <TemplateLayout1/>
        </div>
    )
}

export default Services
