import React from 'react'
import Three from '../Tile2/Three'
const About = () => {
    return (
        <div>
          
            
                <Three />
          


            </div>

    )
}

export default About
